import { BreadcrumbItem, Breadcrumbs, Container, Heading, Page, Section } from '@troon/ui';
import { Title } from '@solidjs/meta';
import type { RouteDefinition } from '@solidjs/router';

export default function VisaInfinitePage() {
	return (
		<>
			<Container class="pt-4">
				<Title>Troon Rewards® Visa Benefits | Troon Rewards | Troon</Title>
				<Breadcrumbs>
					<BreadcrumbItem href="/rewards">Troon Rewards</BreadcrumbItem>
					<BreadcrumbItem href="/rewards/visa">Visa</BreadcrumbItem>
				</Breadcrumbs>
			</Container>
			<Container size="small">
				<Page>
					<Heading as="h1">Troon Rewards® Visa Benefits</Heading>

					<Section class="rounded border border-neutral bg-neutral-100 p-4 md:p-8">
						<Heading as="h2" size="h4">
							Down for Maintenance
						</Heading>
						<p>
							We’re sorry, but the Visa Infinite, Visa Signature, and Visa Infinite Privilege redemption is currently
							down for maintenance. Please check back soon!
						</p>
					</Section>
				</Page>
			</Container>
		</>
	);
}

export const route = {} satisfies RouteDefinition;
